var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-box" }, [
    _c("div", [
      _c("span", { staticClass: "test" }, [_vm._v("罚款金额合计：")]),
      _c("span", { staticClass: "money" }, [_vm._v(_vm._s(_vm.totalNum))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }