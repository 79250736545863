<!--
 * @Author: 冯杰
 * @Date: 2021-08-05 10:43:43
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-09 20:25:51
 * @FilePath: 头部看板
-->
<template>
    <div class="header-box">
        <div>
          <span class="test">罚款金额合计：</span>
          <span class="money">{{totalNum}}</span>
        </div>
    </div>
</template>

<script>
import request from '@/found/utils/request';

export default {
  data() {
    return {
      totalNum: 0, // 罚款金额合计
    };
  },
  props: {
    searchFormData: Object,
  },
  watch: {
    // searchFormData(val) {
    //   this.getData(val);
    //   console.log(val, 123);
    // },
    searchFormData: {
      deep: true,
      immediate: true,
      handler(val) {
        console.log(val, 123);
        this.getData(val);
      },
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData(params) {
      request
        .post('/tpm/tpmpunishmentwrit/sumAmount', params)
        .then((res) => {
          if (!res.success) return;
          this.totalNum = res.result || 0;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.header-box{
    min-width: 1431px;
    width: 100%;
    height: 80px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    div{
        margin-right: 24px;
        width: 20%;
        height: 80px;
        font-family: PingFangSC-Regular;
        color: #333333;
        border-radius: 4px;
        background-image: linear-gradient(-45deg, #EEF6FF 0%, #FFFFFF 100%);
        box-shadow: 0px 0px 10px 0px rgba(14,93,195,0.1);
        span{
            display: block;
            font-size: 16px;
        }
        .test{
            height: 24px;
            line-height: 24px;
            margin: 0px 0 10px 20px;
        }
        .money{
            font-size: 50px;
            color: #0E5DC3;
            font-weight: 500;
            margin-right: 20px;
            margin-left: 20px;
            text-align: right;
        }
    }
}
</style>
